body,
table,
h4,
h3,
h2,
h1,
p,
a,
span,
i {
    font-family: Helvetica, Arial, sans-serif;
}

table#users-table,
table#jobs-table,
table#cvs-table,
table#orders-table {
    tbody {
        tr {
            td {
                font-size: 14px;

                h4 {
                    font-size: 18px;
                    font-weight: 600;
                    display: inline-block;
                    margin-bottom: 10px;
                }

                h5 {
                    font-weight: 400;
                    padding-bottom: 5px;
                    margin-bottom: 5px;
                    border-bottom: 1px solid #f4f4f4;
                    color: #999;
                    font-size: 13px;
                }
            }

            td.icons {
                padding: 8px 0 !important;
                width: 40px;

                i {
                    display: inline-block;
                }
            }

            td {
                div.logo {
                    width: 140px;
                    height: 140px;

                    img {
                        width: 140px;
                        height: 140px;
                    }
                }
            }

            &.hover {
                td {
                    background-color: lighten(#ff0, 40%);
                }
            }

            &.auto-banned {
                td {
                    background-color: lighten(#f00, 40%);
                }
            }
        }
    }
}

i.info {
    position: relative;
    top: -2px;
    right: -5px;
}

div.popover {
    border: none;
    border-radius: 0;
    max-width: 600px;

    &.right > .arrow {
        border-right-color: transparent;
        border-left-width: 0;
    }

    &.left > .arrow {
        border-left-color: transparent;
        border-right-width: 0;
    }

    div.popover-content {
        h4 {
            margin: 10px 0;

            &:first-of-type {
                margin-top: 0;
            }
        }
    }
}

.mb10 {
    margin-bottom: 10px;
}

.table {
    thead {
        tr {
            th {
                background: #fff;
                color: #777;
                border-bottom: 2px solid #999;
                border-left: 1px solid #999;
                font-size: 12px;
                font-weight: 400;
                padding: 4px 8px;
                text-align: left;
            }
        }
    }
}

a {
    color: #09f;

    &:hover {
        color: #09f;
        text-decoration: underline;
    }
}

.blue {
    color: #09f;
}

.red {
    color: #ff4100;
}

.yellow {
    color: #ff8e00;
}

.green {
    color: #00a656;
}

.btn-bg-blue {
    background-color: #09f;
    color: #fff;

    &:focus,
    &:hover,
    &:active {
        background-color: darken(#09f, 20%);
        color: #fff;
    }

    &.active {
        background-color: darken(#09f, 20%);
        &:hover,
        &:active {
            background-color: darken(#09f, 40%);
            color: #fff;
        }
    }
}

.btn-bg-red {
    background-color: #ff4100;
    color: #fff;

    &:focus,
    &:hover,
    &:active {
        background-color: darken(#ff4100, 20%);
        color: #fff;
    }
}

.btn-bg-pink {
    background-color: #ff008e;
    color: #fff;

    &:focus,
    &:hover,
    &:active {
        background-color: darken(#ff008e, 20%);
        color: #fff;
    }
}

.btn-bg-yellow {
    background-color: #ff8e00;
    color: #fff;

    &:focus,
    &:hover,
    &:active {
        background-color: darken(#ff8e00, 20%);
        color: #fff;
    }
}

.btn-bg-green {
    background-color: #00a656;
    color: #fff;

    &:focus,
    &:hover,
    &:active {
        background-color: darken(#00a656, 20%);
        color: #fff;
    }
}

.btn-bg-danger {
    background-color: #f00;
    color: #fff;

    &:focus,
    &:hover,
    &:active {
        background-color: darken(#f00, 20%);
        color: #fff;
    }
}

.btn-default {
    background-color: #09f;
    border: none !important;
    color: #fff;
    &:visited {
        background-color: #09f;
    }

    &:focus,
    &:hover,
    &:active,
    &:disabled {
        border: none !important;
        background-color: darken(#09f, 20%);
        color: #fff;
    }

    &[type="button"]:disabled {
        background-color: darken(#09f, 20%);
        &:hover {
            background-color: darken(#09f, 20%);
        }
    }
}

.btn {
    border-radius: 0 !important;
    border: none !important;
}

.btn-sm {
    font-size: 13.5px !important;
}

.content-wrapper {
    background-color: #fff;
}

.box {
    border-radius: 0;
    border-top: 3px solid #d2d6de;
    box-shadow: none;

    &.box-primary {
        border-top-color: #09f;
    }
}

.box-header {
    label {
        font-weight: 400;
        font-size: 13px;

        .form-control {
            margin-top: 0;
            padding: 3px 7px;
            line-height: normal;
            font-size: 14px;
            color: #333;
            max-width: 300px;
        }

        & > .form-control {
            margin-top: 5px;
        }

        .input-group {
            margin-top: 5px;
        }
    }
}

.content-header {
    h1 {
        margin: 0;
        font-size: 22px;
        font-weight: 600;
    }
}

select {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}

.btns {
    padding-right: 0 !important;
    a,
    span,
    input,
    button {
        display: block;
        margin-bottom: 1px;
        text-align: left;
        width: 100%;

        i {
            display: inline-block;
            margin-right: 5px;
        }

        &.small {
            display: block;
            float: left;
            margin-right: 1px;

            i {
                margin-right: 0;
                width: 12px;
                height: 18px;
                text-align: center;
            }
        }
    }
}

.width120 {
    width: 125px;
}

span.m-hint {
    cursor: help;
    border-bottom: 1px solid gray;
}

.hint-list {
    li {
        margin-bottom: 5px;

        &.inactive {
            i,
            a {
                opacity: 0.5;
            }
        }
    }
}

.pagination {
    & > li {
        a,
        span {
            border-radius: 25px !important;
            margin-right: 2px;
            border: none;
            background: #eee;
            color: #333;

            &:hover {
                background: darken(#eee, 20%);
                color: #333;
            }
        }

        &.active {
            a,
            span {
                background: #777;
                color: #fff;
            }
        }
    }
}

.pager-info {
    font-size: 14px;
    border-bottom: 1px solid #999;
    padding-bottom: 5px;
    margin: 0 0 20px;
}

.inactive {
    opacity: 0.5 !important;
}

.sidebar-menu {
    font-size: 14px;
    li {
        i {
            margin-right: 10px;
        }
    }
}

p.empty {
    border-top: 2px solid #999;
    padding-top: 10px;
    font-size: 14px;
}

td.order {
    table {
        tr {
            th {
                border-bottom: none !important;
                background: #eee !important;
            }

            td {
                font-size: 13px !important;
            }
        }
    }
}

.tbl {
    td {
        font-size: 14px !important;
    }
}

h4 {
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 10px;
}

.add-services {
    position: relative;
    left: 5px;
    top: 18px;
}

.modal-body {
    input[type=checkbox] {
        margin-top: 1px;
    }

    .col-sm-12 > label {
        margin-bottom: 10px;
        font-size: 18px;
    }
}
.box-body, .box-header {
    padding: 10px 0;
}

td {
    p {
        i.inline-block {
            position: relative;
            top: 1px;
        }
    }
}

div.form-group {
    label {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 5px;
    }
}

.alert {
    border: none !important;
    border-radius: 0;
    font-size: 14px;
    position: relative;
    .close {
        position: absolute;
        top: 5px;
        right: 10px;
    }
}

div.filters {
    border-top: 2px solid lighten(#09f, 30%);
    border-bottom: 2px solid lighten(#09f, 30%);
    padding: 5px 0;
    background-color: lighten(#09f, 40%);
    label {
        margin-bottom: 5px;
        input, select {
            max-width: 200px !important;
        }
    }
    button {
        margin-bottom: 5px;
    }
}

.sort-item {
    position: relative;
    .sort-arr {
        font-size: 16px;
        position: absolute;
        top: -3px;
        right: -12px;
    }
}

.table {
    thead {
        tr {
            th {
                background-color: lighten(#09f, 45%);
                vertical-align: middle !important;
                border-bottom-color: lighten(#09f, 30%);
                border-left-color: lighten(#09f, 40%);
                text-align: center;
                //white-space: nowrap;
                padding: 5px 10px;
                color: #333;
                &.no-border {
                    border: none !important;
                }
                &:first-child {
                    border-left: none;
                }
                &.grp {
                    border-bottom-color: lighten(#09f, 40%);
                    border-bottom-width: 1px;
                }
            }
        }
    }
}

.ui-menu {
    margin-top: 2px;
    max-width: 300px;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
    .ui-menu-item {
        padding: 5px 10px 6px;
        border-bottom: 1px solid lighten(#09f, 30%);
        cursor: pointer;
        &:hover {
            background-color: lighten(#09f, 30%);
        }
        .ui-menu-item-wrapper {
            font-size: 16px;
        }
        &:last-child {
            border-bottom: none !important;
        }
    }
}

a.trigger {
    display: inline-block;
    border-bottom: 1px dashed;
    text-decoration: none;
    &:hover {
        border-bottom: 1px solid #fff;
        text-decoration: none;
    }
}

.modal-header {
    background-color: #09f;
    position: relative;
    color: #fff;

    h1 {
        font-weight: bold;
        font-size: 18px;
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

div.radio,
div.checkbox {
    label {
        position: relative;
        input {
            position: absolute;
            top: -2px;
        }
    }
}

.js-image-container {
    button.close {
        opacity: 0.4;
        position: absolute;
        right: 3px;
        top: 3px;
        background: #fff;
        border-radius: 50%;
        width: 22px;
        height: 21px;
    }
}

td {
    h4 {
        a {
            //white-space: nowrap;
        }
    }
}

.skin-blue .main-header .logo {
    background-color: #fff;
    &:hover {
        background-color: #fff;
    }
}

.skin-blue .main-header .navbar {
    background-color: #222d32;
}

.dropdown-menu {
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 0 !important;
}

.navbar-nav>.user-menu>.dropdown-menu>.user-body {
    border: none !important;
}

.navbar-nav>.user-menu>.dropdown-menu>.user-body a {
    color: #fff !important;
}

.navbar {
    input {
        border: none !important;
        font-size: 14px !important;
        padding: 3px 5px !important;
        height: auto !important;
    }

    button {
        padding: 3px 12px !important;
    }
}

.login-page {
    background: #222d32;
    .login-box-body {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.95);
    }
}

.vertical-text {
    transform: rotate(90deg);
    transform-origin: left top 0;
}

.has-error {
    strong {
        font-weight: 400;
        font-size: 13px;
    }
}

.glyphicon.spinning {
    animation: spin 1s infinite linear;
    -webkit-animation: spin2 1s infinite linear;
}

@keyframes spin {
    from { transform: scale(1) rotate(0deg);}
    to { transform: scale(1) rotate(360deg);}
}

@-webkit-keyframes spin2 {
    from { -webkit-transform: rotate(0deg);}
    to { -webkit-transform: rotate(360deg);}
}

.glyphicon-left {
    margin-right: 7px;
}

.btn-short {
    display: inline-block !important;
    width: auto !important;
}

div.reminder {
    padding: 20px 10px 20px 40px;
    border-bottom: 1px solid #ccc;
    position: relative;

    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        border-bottom: none;
    }
    span.reminder-icon {
        position: absolute;
        font-size: 30px;
        left: 0px;
        &.danger {
            color: #ff4100;
        }
        &.warning {
            color: #ff8e00;
        }
        &.default {
            display: none;
        }
    }
    select.input-sm {
        line-height: 18px;
    }
}

.search-result-popup {
    border-bottom: 1px solid #cccccc;
    margin-bottom: 10px;
    padding-bottom: 10px;
    &:last-child {
        border-bottom: none;
        margin-bottom: 0;
    }
}

.log-item {
    margin-top: 20px !important;

    h1.part {
        font-size: 22px;
        font-weight: bold;
        border-bottom: 1px solid #ccc;
    }

    h2 {
        margin: 20px 0 0;
        padding: 0 0 5px !important;
        clear: both;
        &.part {
            border-bottom: 1px solid #ddd;
        }
    }

    .alert {
        background-color: #ff4100 !important;
        .inner-informer {
            b, span {
                color: #ff4100;
            }
            span {
                border-bottom-color: #ff4100;
            }
        }
    }

    .info {
        background-color: #09f !important;
        .inner-informer {
            b, span {
                color: #09f;
            }
            span {
                border-bottom-color: #09f;
            }
        }
    }

    .small-informer {
        background-color: #09f;
        margin: 0 5px 5px 0;

        min-width: 120px;

        padding: 15px 20px;
        a {
            color: #fff;
            text-decoration: none;
        }
        span {
            font-size: 14px;
            color: #fff;
            text-align: center;
            display: block;
            border-bottom: 1px solid #fff;
            padding-bottom: 5px;
        }
        b {
            margin-top: 5px;
            color: #fff;
            font-size: 28px;
            font-weight: bold;
            display: block;
            text-align: center;
        }
        float: left;
    }

    .big-informer {
        background-color: #00a656;
        padding: 15px 20px;
        margin: 10px 10px 10px 0;
        height: 118px;
        display: block;

        span {
            font-size: 18px;
            color: #fff;
            white-space: nowrap;
            display: block;
            border-bottom: 1px solid #fff;
            padding-bottom: 5px;
        }

        b {
            margin-top: 10px;
            color: #fff;
            font-size: 52px;
            font-weight: bold;
            display: block;
            text-align: center;
        }

        .inner-informer {
            margin: 10px 3px 3px 0;

            background-color: #fff;

            span {
                font-size: 12px;
                display: block;
                border-bottom: 1px solid;
                margin: 5px;
                width: 60px;
                text-align: center;
            }

            b {
                margin: 5px;
                font-size: 24px;
                font-weight: bold;
                display: block;
                text-align: center;
            }

            float: left;
        }
        float: left;
    }
    clear: both;
}

h2.table-title {
    font-size: 15px;
    margin: 10px 0 7px 0;
}

table.report-table,
table.visa-table {
    width: 100%;
    tr {
        th {
            border-bottom: 2px solid lighten(#09f, 30%);
            padding: 4px 0;
            font-size: 12px;
            vertical-align: middle;
            text-align: center;
            font-weight: normal;
            white-space: nowrap;
        }

        td {
            border-top: 1px solid lighten(#09f, 30%);
            padding: 4px 10px;
            font-size: 13px;
            vertical-align: top;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
            white-space: nowrap;
        }
    }

    &.w70 {
        width: 70% !important;
    }
}