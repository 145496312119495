body,
table,
h4,
h3,
h2,
h1,
p,
a,
span,
i {
  font-family: Helvetica, Arial, sans-serif;
}
table#users-table tbody tr td,
table#jobs-table tbody tr td,
table#cvs-table tbody tr td,
table#orders-table tbody tr td {
  font-size: 14px;
}
table#users-table tbody tr td h4,
table#jobs-table tbody tr td h4,
table#cvs-table tbody tr td h4,
table#orders-table tbody tr td h4 {
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 10px;
}
table#users-table tbody tr td h5,
table#jobs-table tbody tr td h5,
table#cvs-table tbody tr td h5,
table#orders-table tbody tr td h5 {
  font-weight: 400;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #f4f4f4;
  color: #999;
  font-size: 13px;
}
table#users-table tbody tr td.icons,
table#jobs-table tbody tr td.icons,
table#cvs-table tbody tr td.icons,
table#orders-table tbody tr td.icons {
  padding: 8px 0 !important;
  width: 40px;
}
table#users-table tbody tr td.icons i,
table#jobs-table tbody tr td.icons i,
table#cvs-table tbody tr td.icons i,
table#orders-table tbody tr td.icons i {
  display: inline-block;
}
table#users-table tbody tr td div.logo,
table#jobs-table tbody tr td div.logo,
table#cvs-table tbody tr td div.logo,
table#orders-table tbody tr td div.logo {
  width: 140px;
  height: 140px;
}
table#users-table tbody tr td div.logo img,
table#jobs-table tbody tr td div.logo img,
table#cvs-table tbody tr td div.logo img,
table#orders-table tbody tr td div.logo img {
  width: 140px;
  height: 140px;
}
table#users-table tbody tr.hover td,
table#jobs-table tbody tr.hover td,
table#cvs-table tbody tr.hover td,
table#orders-table tbody tr.hover td {
  background-color: #ffffcc;
}
table#users-table tbody tr.auto-banned td,
table#jobs-table tbody tr.auto-banned td,
table#cvs-table tbody tr.auto-banned td,
table#orders-table tbody tr.auto-banned td {
  background-color: #ffcccc;
}
i.info {
  position: relative;
  top: -2px;
  right: -5px;
}
div.popover {
  border: none;
  border-radius: 0;
  max-width: 600px;
}
div.popover.right > .arrow {
  border-right-color: transparent;
  border-left-width: 0;
}
div.popover.left > .arrow {
  border-left-color: transparent;
  border-right-width: 0;
}
div.popover div.popover-content h4 {
  margin: 10px 0;
}
div.popover div.popover-content h4:first-of-type {
  margin-top: 0;
}
.mb10 {
  margin-bottom: 10px;
}
.table thead tr th {
  background: #fff;
  color: #777;
  border-bottom: 2px solid #999;
  border-left: 1px solid #999;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 8px;
  text-align: left;
}
a {
  color: #09f;
}
a:hover {
  color: #09f;
  text-decoration: underline;
}
.blue {
  color: #09f;
}
.red {
  color: #ff4100;
}
.yellow {
  color: #ff8e00;
}
.green {
  color: #00a656;
}
.btn-bg-blue {
  background-color: #09f;
  color: #fff;
}
.btn-bg-blue:focus,
.btn-bg-blue:hover,
.btn-bg-blue:active {
  background-color: #005c99;
  color: #fff;
}
.btn-bg-blue.active {
  background-color: #005c99;
}
.btn-bg-blue.active:hover,
.btn-bg-blue.active:active {
  background-color: #001f33;
  color: #fff;
}
.btn-bg-red {
  background-color: #ff4100;
  color: #fff;
}
.btn-bg-red:focus,
.btn-bg-red:hover,
.btn-bg-red:active {
  background-color: #992700;
  color: #fff;
}
.btn-bg-pink {
  background-color: #ff008e;
  color: #fff;
}
.btn-bg-pink:focus,
.btn-bg-pink:hover,
.btn-bg-pink:active {
  background-color: #990055;
  color: #fff;
}
.btn-bg-yellow {
  background-color: #ff8e00;
  color: #fff;
}
.btn-bg-yellow:focus,
.btn-bg-yellow:hover,
.btn-bg-yellow:active {
  background-color: #995500;
  color: #fff;
}
.btn-bg-green {
  background-color: #00a656;
  color: #fff;
}
.btn-bg-green:focus,
.btn-bg-green:hover,
.btn-bg-green:active {
  background-color: #004021;
  color: #fff;
}
.btn-bg-danger {
  background-color: #f00;
  color: #fff;
}
.btn-bg-danger:focus,
.btn-bg-danger:hover,
.btn-bg-danger:active {
  background-color: #990000;
  color: #fff;
}
.btn-default {
  background-color: #09f;
  border: none !important;
  color: #fff;
}
.btn-default:visited {
  background-color: #09f;
}
.btn-default:focus,
.btn-default:hover,
.btn-default:active,
.btn-default:disabled {
  border: none !important;
  background-color: #005c99;
  color: #fff;
}
.btn-default[type="button"]:disabled {
  background-color: #005c99;
}
.btn-default[type="button"]:disabled:hover {
  background-color: #005c99;
}
.btn {
  border-radius: 0 !important;
  border: none !important;
}
.btn-sm {
  font-size: 13.5px !important;
}
.content-wrapper {
  background-color: #fff;
}
.box {
  border-radius: 0;
  border-top: 3px solid #d2d6de;
  box-shadow: none;
}
.box.box-primary {
  border-top-color: #09f;
}
.box-header label {
  font-weight: 400;
  font-size: 13px;
}
.box-header label .form-control {
  margin-top: 0;
  padding: 3px 7px;
  line-height: normal;
  font-size: 14px;
  color: #333;
  max-width: 300px;
}
.box-header label > .form-control {
  margin-top: 5px;
}
.box-header label .input-group {
  margin-top: 5px;
}
.content-header h1 {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
}
select {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
.btns {
  padding-right: 0 !important;
}
.btns a,
.btns span,
.btns input,
.btns button {
  display: block;
  margin-bottom: 1px;
  text-align: left;
  width: 100%;
}
.btns a i,
.btns span i,
.btns input i,
.btns button i {
  display: inline-block;
  margin-right: 5px;
}
.btns a.small,
.btns span.small,
.btns input.small,
.btns button.small {
  display: block;
  float: left;
  margin-right: 1px;
}
.btns a.small i,
.btns span.small i,
.btns input.small i,
.btns button.small i {
  margin-right: 0;
  width: 12px;
  height: 18px;
  text-align: center;
}
.width120 {
  width: 125px;
}
span.m-hint {
  cursor: help;
  border-bottom: 1px solid gray;
}
.hint-list li {
  margin-bottom: 5px;
}
.hint-list li.inactive i,
.hint-list li.inactive a {
  opacity: 0.5;
}
.pagination > li a,
.pagination > li span {
  border-radius: 25px !important;
  margin-right: 2px;
  border: none;
  background: #eee;
  color: #333;
}
.pagination > li a:hover,
.pagination > li span:hover {
  background: #bbbbbb;
  color: #333;
}
.pagination > li.active a,
.pagination > li.active span {
  background: #777;
  color: #fff;
}
.pager-info {
  font-size: 14px;
  border-bottom: 1px solid #999;
  padding-bottom: 5px;
  margin: 0 0 20px;
}
.inactive {
  opacity: 0.5 !important;
}
.sidebar-menu {
  font-size: 14px;
}
.sidebar-menu li i {
  margin-right: 10px;
}
p.empty {
  border-top: 2px solid #999;
  padding-top: 10px;
  font-size: 14px;
}
td.order table tr th {
  border-bottom: none !important;
  background: #eee !important;
}
td.order table tr td {
  font-size: 13px !important;
}
.tbl td {
  font-size: 14px !important;
}
h4 {
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 10px;
}
.add-services {
  position: relative;
  left: 5px;
  top: 18px;
}
.modal-body input[type=checkbox] {
  margin-top: 1px;
}
.modal-body .col-sm-12 > label {
  margin-bottom: 10px;
  font-size: 18px;
}
.box-body,
.box-header {
  padding: 10px 0;
}
td p i.inline-block {
  position: relative;
  top: 1px;
}
div.form-group label {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
}
.alert {
  border: none !important;
  border-radius: 0;
  font-size: 14px;
  position: relative;
}
.alert .close {
  position: absolute;
  top: 5px;
  right: 10px;
}
div.filters {
  border-top: 2px solid #99d6ff;
  border-bottom: 2px solid #99d6ff;
  padding: 5px 0;
  background-color: #ccebff;
}
div.filters label {
  margin-bottom: 5px;
}
div.filters label input,
div.filters label select {
  max-width: 200px !important;
}
div.filters button {
  margin-bottom: 5px;
}
.sort-item {
  position: relative;
}
.sort-item .sort-arr {
  font-size: 16px;
  position: absolute;
  top: -3px;
  right: -12px;
}
.table thead tr th {
  background-color: #e5f5ff;
  vertical-align: middle !important;
  border-bottom-color: #99d6ff;
  border-left-color: #ccebff;
  text-align: center;
  padding: 5px 10px;
  color: #333;
}
.table thead tr th.no-border {
  border: none !important;
}
.table thead tr th:first-child {
  border-left: none;
}
.table thead tr th.grp {
  border-bottom-color: #ccebff;
  border-bottom-width: 1px;
}
.ui-menu {
  margin-top: 2px;
  max-width: 300px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.ui-menu .ui-menu-item {
  padding: 5px 10px 6px;
  border-bottom: 1px solid #99d6ff;
  cursor: pointer;
}
.ui-menu .ui-menu-item:hover {
  background-color: #99d6ff;
}
.ui-menu .ui-menu-item .ui-menu-item-wrapper {
  font-size: 16px;
}
.ui-menu .ui-menu-item:last-child {
  border-bottom: none !important;
}
a.trigger {
  display: inline-block;
  border-bottom: 1px dashed;
  text-decoration: none;
}
a.trigger:hover {
  border-bottom: 1px solid #fff;
  text-decoration: none;
}
.modal-header {
  background-color: #09f;
  position: relative;
  color: #fff;
}
.modal-header h1 {
  font-weight: bold;
  font-size: 18px;
}
.modal-header .close {
  position: absolute;
  top: 10px;
  right: 10px;
}
div.radio label,
div.checkbox label {
  position: relative;
}
div.radio label input,
div.checkbox label input {
  position: absolute;
  top: -2px;
}
.js-image-container button.close {
  opacity: 0.4;
  position: absolute;
  right: 3px;
  top: 3px;
  background: #fff;
  border-radius: 50%;
  width: 22px;
  height: 21px;
}
.skin-blue .main-header .logo {
  background-color: #fff;
}
.skin-blue .main-header .logo:hover {
  background-color: #fff;
}
.skin-blue .main-header .navbar {
  background-color: #222d32;
}
.dropdown-menu {
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 0 !important;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-body {
  border: none !important;
}
.navbar-nav > .user-menu > .dropdown-menu > .user-body a {
  color: #fff !important;
}
.navbar input {
  border: none !important;
  font-size: 14px !important;
  padding: 3px 5px !important;
  height: auto !important;
}
.navbar button {
  padding: 3px 12px !important;
}
.login-page {
  background: #222d32;
}
.login-page .login-box-body {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.95);
}
.vertical-text {
  transform: rotate(90deg);
  transform-origin: left top 0;
}
.has-error strong {
  font-weight: 400;
  font-size: 13px;
}
.glyphicon.spinning {
  animation: spin 1s infinite linear;
  -webkit-animation: spin2 1s infinite linear;
}
@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.glyphicon-left {
  margin-right: 7px;
}
.btn-short {
  display: inline-block !important;
  width: auto !important;
}
div.reminder {
  padding: 20px 10px 20px 40px;
  border-bottom: 1px solid #ccc;
  position: relative;
}
div.reminder:first-child {
  padding-top: 0;
}
div.reminder:last-child {
  border-bottom: none;
}
div.reminder span.reminder-icon {
  position: absolute;
  font-size: 30px;
  left: 0px;
}
div.reminder span.reminder-icon.danger {
  color: #ff4100;
}
div.reminder span.reminder-icon.warning {
  color: #ff8e00;
}
div.reminder span.reminder-icon.default {
  display: none;
}
div.reminder select.input-sm {
  line-height: 18px;
}
.search-result-popup {
  border-bottom: 1px solid #cccccc;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.search-result-popup:last-child {
  border-bottom: none;
  margin-bottom: 0;
}
.log-item {
  margin-top: 20px !important;
  clear: both;
}
.log-item h1.part {
  font-size: 22px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}
.log-item h2 {
  margin: 20px 0 0;
  padding: 0 0 5px !important;
  clear: both;
}
.log-item h2.part {
  border-bottom: 1px solid #ddd;
}
.log-item .alert {
  background-color: #ff4100 !important;
}
.log-item .alert .inner-informer b,
.log-item .alert .inner-informer span {
  color: #ff4100;
}
.log-item .alert .inner-informer span {
  border-bottom-color: #ff4100;
}
.log-item .info {
  background-color: #09f !important;
}
.log-item .info .inner-informer b,
.log-item .info .inner-informer span {
  color: #09f;
}
.log-item .info .inner-informer span {
  border-bottom-color: #09f;
}
.log-item .small-informer {
  background-color: #09f;
  margin: 0 5px 5px 0;
  min-width: 120px;
  padding: 15px 20px;
  float: left;
}
.log-item .small-informer a {
  color: #fff;
  text-decoration: none;
}
.log-item .small-informer span {
  font-size: 14px;
  color: #fff;
  text-align: center;
  display: block;
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
}
.log-item .small-informer b {
  margin-top: 5px;
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  display: block;
  text-align: center;
}
.log-item .big-informer {
  background-color: #00a656;
  padding: 15px 20px;
  margin: 10px 10px 10px 0;
  height: 118px;
  display: block;
  float: left;
}
.log-item .big-informer span {
  font-size: 18px;
  color: #fff;
  white-space: nowrap;
  display: block;
  border-bottom: 1px solid #fff;
  padding-bottom: 5px;
}
.log-item .big-informer b {
  margin-top: 10px;
  color: #fff;
  font-size: 52px;
  font-weight: bold;
  display: block;
  text-align: center;
}
.log-item .big-informer .inner-informer {
  margin: 10px 3px 3px 0;
  background-color: #fff;
  float: left;
}
.log-item .big-informer .inner-informer span {
  font-size: 12px;
  display: block;
  border-bottom: 1px solid;
  margin: 5px;
  width: 60px;
  text-align: center;
}
.log-item .big-informer .inner-informer b {
  margin: 5px;
  font-size: 24px;
  font-weight: bold;
  display: block;
  text-align: center;
}
h2.table-title {
  font-size: 15px;
  margin: 10px 0 7px 0;
}
table.report-table,
table.visa-table {
  width: 100%;
}
table.report-table tr th,
table.visa-table tr th {
  border-bottom: 2px solid #99d6ff;
  padding: 4px 0;
  font-size: 12px;
  vertical-align: middle;
  text-align: center;
  font-weight: normal;
  white-space: nowrap;
}
table.report-table tr td,
table.visa-table tr td {
  border-top: 1px solid #99d6ff;
  padding: 4px 10px;
  font-size: 13px;
  vertical-align: top;
  white-space: nowrap;
}
table.report-table tr td:first-child,
table.visa-table tr td:first-child {
  padding-left: 0;
}
table.report-table tr td:last-child,
table.visa-table tr td:last-child {
  padding-right: 0;
}
table.report-table.w70,
table.visa-table.w70 {
  width: 70% !important;
}
